<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import type { CmsElementForm } from "@shopware-pwa/composables-next";
import { useCmsTranslations } from "@shopware-pwa/composables-next";
import { ApiClientError } from "@shopware/api-client";
import {
  useCmsElementConfig,
  useNavigationContext,
  useSalutations,
  useShopwareContext,
} from "#imports";
import { computed, reactive, ref } from "vue";
import { defu } from "defu";
import { mdiCheckCircleOutline } from "@mdi/js";

const props = defineProps<{
  content: CmsElementForm;
}>();

type Translations = {
  form: {
    salutation: string;
    salutationPlaceholder: string;
    firstName: string;
    firstNamePlaceholder: string;
    lastName: string;
    lastNamePlaceholder: string;
    email: string;
    emailPlaceholder: string;
    phone: string;
    phonePlaceholder: string;
    subject: string;
    subjectPlaceholder: string;
    comment: string;
    commentPlaceholder: string;
    privacy: string;
    dataProtection: string;
    submit: string;
    messages: {
      contactFormSuccess: string;
    };
  };
};

let translations: Translations = {
  form: {
    salutation: "Salutation",
    salutationPlaceholder: "Enter salutation...",
    firstName: "First name",
    firstNamePlaceholder: "Enter first name...",
    lastName: "Last name",
    lastNamePlaceholder: "Enter last name...",
    email: "Email address",
    emailPlaceholder: "Enter email address...",
    phone: "Phone number",
    phonePlaceholder: "Enter phone number...",
    subject: "Subject",
    subjectPlaceholder: "Enter subject...",
    comment: "Comment",
    commentPlaceholder: "Enter comment...",
    privacy: "Privacy",
    dataProtection: "I have read the data protection information.",
    submit: "Submit",
    messages: {
      contactFormSuccess:
        "We have received your contact request and will process it as soon as possible.",
    },
  },
};

translations = defu(translations, useCmsTranslations()) as Translations;

const loading = ref<boolean>();
const formSent = ref<boolean>(false);
const errorMessages = ref<any[]>([]);
const { getSalutations } = useSalutations();
const { foreignKey } = useNavigationContext();
const { apiClient } = useShopwareContext();
const { getConfigValue } = useCmsElementConfig(props.content);

const getConfirmationText = computed(
  () =>
    getConfigValue("confirmationText") ??
    translations.form.messages.contactFormSuccess,
);
const getFormTitle = computed(() => getConfigValue("title") || "Contact");
const state = reactive({
  salutationId: "",
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  comment: "",
  phone: "",
  checkbox: false,
});

const rules = computed(() => ({
  email: {
    required,
    email,
  },
  firstName: {
    required,
    minLength: minLength(3),
  },
  lastName: {
    required,
    minLength: minLength(3),
  },
  salutationId: {
    required,
  },
  phone: {
    required,
    minLength: minLength(3),
  },
  subject: {
    required,
    minLength: minLength(3),
  },
  comment: {
    required,
    minLength: minLength(10),
  },
  checkbox: {
    required,
    isTrue: (value: any) => value === true,
  },
}));

const $v = useVuelidate(rules, state);
const invokeSubmit = async () => {
  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid) {
    loading.value = true;
    try {
      await apiClient.invoke("sendContactMail post /contact-form", {
        body: {
          ...state,
          navigationId: foreignKey.value,
        }
      }).then((data) => {return data.data});
      formSent.value = true;
    } catch (e) {
      if (e instanceof ApiClientError) {
        errorMessages.value = e.details.errors;
      }
    } finally {
      loading.value = false;
    }
  }
};
</script>
<template>
  <v-form @submit.prevent="invokeSubmit">
    <v-row>
      <v-col cols="12" sm="4">
        <v-select
            variant="solo"
            :label="translations.form.salutation"
            :placeholder="translations.form.salutation"
            :items="getSalutations"
            item-title="displayName"
            item-value="id"
            v-model="state.salutationId"
            :error-messages="$v.salutationId.$errors.map(e => e.$message)"
            required
            @blur="$v.salutationId.$touch"
            @change="$v.salutationId.$touch"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
            variant="solo"
            v-model="state.firstName"
            :label="translations.form.firstName"
            :placeholder="translations.form.firstNamePlaceholder"
            autocomplete="first-name"
            :error-messages="$v.firstName.$errors.map(e => e.$message)"
            @blur="$v.firstName.$touch"
            @input="$v.firstName.$touch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
            variant="solo"
            v-model="state.lastName"
            :label="translations.form.lastName"
            :placeholder="translations.form.lastNamePlaceholder"
            :error-messages="$v.lastName.$errors.map(e => e.$message)"
            autocomplete="last-name"
            @blur="$v.lastName.$touch"
            @input="$v.lastName.$touch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            variant="solo"
            v-model="state.email"
            :label="translations.form.email"
            :placeholder="translations.form.emailPlaceholder"
            type="email"
            :error-messages="$v.email.$errors.map(e => e.$message)"
            autocomplete="email"
            @blur="$v.email.$touch"
            @input="$v.email.$touch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            variant="solo"
            v-model="state.phone"
            :label="translations.form.phone"
            :placeholder="translations.form.phonePlaceholder"
            :error-messages="$v.phone.$errors.map(e => e.$message)"
            autocomplete="phone"
            @blur="$v.phone.$touch"
            @input="$v.phone.$touch"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            variant="solo"
            v-model="state.subject"
            :label="translations.form.subject"
            :placeholder="translations.form.subjectPlaceholder"
            :error-messages="$v.subject.$errors.map(e => e.$message)"
            autocomplete="subject"
            @blur="$v.subject.$touch"
            @input="$v.subject.$touch"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
            variant="solo"
            v-model="state.comment"
            :label="translations.form.comment"
            :placeholder="translations.form.commentPlaceholder"
            :error-messages="$v.comment.$errors.map(e => e.$message)"
            autocomplete="comment"
            @blur="$v.comment.$touch"
            @input="$v.comment.$touch"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-checkbox
            :label="translations.form.dataProtection"
            v-model="state.checkbox"
            :error-messages="$v.checkbox.$errors.map(e => e.$message)"
        ></v-checkbox>
      </v-col>
      <v-col cols="auto" class="ms-auto">
        <v-btn
            variant="flat"
            color="primary"
            rounded
            size="x-large"
            class="text-body-1 font-weight-bold"
            type="submit">
          <span>Abschicken</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
